import * as React from "react";
import { useTranslation } from "react-i18next";
import { HeadbotRoute } from "../../../services/Constants/HeadbotRoute";
import { StandardPageTemplate } from "../../templates/StandardPageTemplate/StandardPageTemplate";
import { SignupForm } from "./SignupForm/SignupForm";
export const SignupPage: React.FC = () => {
    const [t] = useTranslation();
    const title = t("signupPage.title");
    const description = t("signupPage.description");
    return (
        <StandardPageTemplate title={title} description={description} canonical={HeadbotRoute.Signup}>
            <SignupForm />
        </StandardPageTemplate>
    );
};
