import { assertValidEmail, assertValidPassword, OrderDetails, ValidationError } from "@headbot/library";
import { t } from "i18next";
import * as React from "react";
import { useCallback, useMemo } from "react";
import { Trans } from "react-i18next";
import { IPostUserRequestResponseBody, usePostUser } from "../../../../hooks/api/user/usePostUser";
import { useAuth } from "../../../../hooks/useAuth";
import { useInputState } from "../../../../hooks/useInputState";
import { useSessionStorage } from "../../../../hooks/useSessionStorage";
import { HeadbotRoute } from "../../../../services/Constants/HeadbotRoute";
import { SessionStorageKey } from "../../../../services/Constants/SessionStorageKey";
import { PrimaryButton } from "../../../atoms/Button/PrimaryButton";
import { Form } from "../../../atoms/Form/Form";
import { P } from "../../../atoms/P/P";
import { TextInput } from "../../../atoms/TextInput/TextInput";
import { WithoutAuth } from "../../../molecules/Auth/WithoutAuth";
import { CompleteOrderPrompt } from "../../../molecules/CompleteOrderPrompt/CompleteOrderPrompt";
import { RouterLink } from "../../../molecules/RouterLink/RouterLink";
import { Heading, SignupFormContainer } from "./SignupForm.styles";

const getValidationErrorMessage = (email: string, password: string) => {
    try {
        assertValidEmail(email);
        assertValidPassword(password);
    } catch (error) {
        if (error instanceof ValidationError) {
            return error.message;
        }
        throw error;
    }
    return null;
};

export const SignupForm: React.FC = () => {
    const { setAuthToken } = useAuth();
    const [email, , onEmailChanged] = useInputState("");
    const [password, , onPasswordChanged] = useInputState("");
    const [rawOrderDetails, setOrderDetails] = useSessionStorage(SessionStorageKey.PaypalOrderDetails, null);
    const [_, setGuestJobId] = useSessionStorage(SessionStorageKey.GuestJobId, null);
    const orderDetails = useMemo(() => {
        const order = typeof rawOrderDetails === "string" ? (JSON.parse(rawOrderDetails) as OrderDetails) : null;
        return order ?? undefined;
    }, [rawOrderDetails]);

    const onSubmitSuccess = useCallback(
        (response: IPostUserRequestResponseBody) => {
            setOrderDetails(null);
            setGuestJobId(null);
            setAuthToken?.(response.token);
        },
        [setAuthToken, setOrderDetails]
    );

    const { mutation } = usePostUser({ email, password, order: orderDetails }, onSubmitSuccess);

    const onSubmit = useCallback(() => {
        mutation.mutate();
    }, [mutation]);

    const emailPlaceholder = t("signupPage.email");
    const passwordPlaceholder = t("signupPage.password");

    const isLoading = mutation.isLoading === true;
    const validationErrorMessage = useMemo(() => getValidationErrorMessage(email, password), [email, password]);

    const isFormIncomplete = password.length <= 0 || password.length <= 0;

    const isSubmitDisabled = (validationErrorMessage === null && isFormIncomplete === false) === false || isLoading;

    const shouldShowErrorMessage = isFormIncomplete === false && validationErrorMessage !== null;

    return (
        <WithoutAuth>
            <SignupFormContainer>
                <Form onSubmit={onSubmit}>
                    <Heading>{t("signupPage.signup")}</Heading>
                    <CompleteOrderPrompt />
                    <TextInput type="text" value={email} onChange={onEmailChanged} placeholder={emailPlaceholder} disabled={isLoading} />
                    <TextInput
                        type="password"
                        value={password}
                        onChange={onPasswordChanged}
                        placeholder={passwordPlaceholder}
                        disabled={isLoading}
                    />
                    <PrimaryButton disabled={isSubmitDisabled} type="submit">
                        {t("signupPage.signup")}
                    </PrimaryButton>
                    {shouldShowErrorMessage && <span>{validationErrorMessage}</span>}
                </Form>

                <P>
                    <Trans key="alreadyHaveAnAccount">
                        Already have an account? <RouterLink href={HeadbotRoute.Login}>Login</RouterLink>
                    </Trans>
                </P>
            </SignupFormContainer>
        </WithoutAuth>
    );
};
